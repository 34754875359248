import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import ka from './locales/ka.json';


const savedLanguage = localStorage.getItem("language") || "ka"; // თუ არ არსებობს, დეფოლტად ქართული


i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: en
      },
      ka: {
        translation: ka
      }
    },
    lng: savedLanguage,
    fallbackLng: "ka", // fallback when a translation is not available in the selected language
    interpolation: {
      escapeValue: false
    },
  });
  

export default i18n;